import React from 'react'
import '../../css/My_account/overview.css';
import { FaParachuteBox } from "react-icons/fa6";

import profile from '../../assests/homeImage/RecommndedforyouImage/product3.png'
import Footer from '../Footer';
import Navbar from '../Navbar';


const ProfileOverview = () => {
    return (
        <>
            <div className='md:hidden'>
                <Navbar />
            </div>

            <section>
                <div className='profile_card'>
                    <img src={profile} className='overview_pro_pic' />
                    <div className='costumer_deetail_wrap'>
                        <h1 className='costumer_name'>John Doe</h1>
                        <p className='costumer_email'>Johndeo95@gmail.com</p>
                        <h1 className='costumer_number'>+82 8057212546</h1>
                    </div>
                    <button className='edit_pro_btn'>Edit Profile</button>
                </div>

                <div className='overview_deetails_box_wrap' >

                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className='overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>
                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className='overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>
                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className='overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>
                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className=' overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>
                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className=' overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>
                    <div className='overview_box'>
                        <FaParachuteBox size={30} />
                        <h1 className=' overview_ordr_text'>Orders</h1>
                        <p className='overview_p_text'>Lorem ipsum dolor sit amet</p>
                    </div>

                </div>

                <button className='logout_btn'>  LOGOUT </button>

            </section>

            <div className='md:hidden block mt-[50px]'>
                <Footer />
            </div>
        </>

    )
}

export default ProfileOverview