import { BiMenu } from 'react-icons/bi';
import { CiSearch } from 'react-icons/ci';
import { BsCart } from 'react-icons/bs';
import { CiWallet } from 'react-icons/ci';
import { MdKeyboardArrowDown } from 'react-icons/md';
import NavbarmiddleLogo from '../assests/homeImage/navbarLogo/middleLogo.png';
import { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { FaCubesStacked } from "react-icons/fa6";
import { FaBoxOpen } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { CiCreditCard2 } from "react-icons/ci";
import { CiBellOn } from "react-icons/ci";
import *as  CONSTANT from '../constant'
import axios from 'axios'

import '../css/navbar.css';


function Navbar() {

    const [text, setText] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [user, setUser] = useState();
    const [dropdownOpen, setDropdownopen] = useState({
        Allcategories: false,
        Deals: false,
        Search: false,
        Carts: false,
        Wallet: false,
        Signin: false,
        Language: false
    })


    // Navbar Dropdown function
    const handleDeopowntoggle = (link) => {
        setDropdownopen({ ...dropdownOpen, [link]: !dropdownOpen[link] });
    }


    const [toggle, setToggle] = useState(false);


    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const [categories, setCategories] = useState([{
        name: "",
        _id: ""
    }]);
     const fetchCategories = async () => {
      let categoriesArray = [];
      const data = {

        }
      const res = await axios.get(`${CONSTANT.baseUrl}/api/get_cat/`, data);
      for (let item of res.data.DATA) {
          if (item.name) {

            categoriesArray.push(item)
          }

        }
                  
    setCategories(categoriesArray);

      };
     useEffect(() => {
    fetchCategories();
  }, []);
    const handleSubmit = (e) => {

        e.preventDefault();
        setText(e.target.value)

    }

    // for navigation pages
    const navigate = useNavigate();
    var loginButton;
    const logoutHandler = () => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("subscription");
        sessionStorage.removeItem("user_id");
        
        setUser(user);
        navigate("/")
    }
    if (sessionStorage.getItem("user") === null || sessionStorage.getItem("user") === 'undefined') {
        loginButton = <div
            onClick={() => handleDeopowntoggle("Signin")}
            // onMouseLeave={() => handleDeopowntoggle("Signin")}
            className='text-white  relative'>
            <div className="flex items-center">
                <p > Sign In</p>
                <MdKeyboardArrowDown size={20} />
            </div>
            {
                dropdownOpen.Signin && (
                    <div className=" dropdown_functionality">
                        < ul>
                            <li onClick={() => navigate('/signup')} >Create New Account</li>
                            <li onClick={() => navigate('/login')} >Sign In</li>
                        </ul>
                    </div>
                )
            }
        </div>
    }
    else {
        var login_name = sessionStorage.getItem('user') == '' ? '' : sessionStorage.getItem('user');
        loginButton = <div
            onClick={() => handleDeopowntoggle("Signin")}
            // onMouseLeave={() => handleDeopowntoggle("Signin")}
            className='text-white  relative'>
            <div className="flex items-center">
                <p > Hi {login_name} </p>
                <MdKeyboardArrowDown size={20} />
            </div>
            {
                dropdownOpen.Signin && (
                    <div className=" dropdown_functionality">
                        < ul>
                            <li onClick={() => { logoutHandler(); }} >Logout</li>

                        </ul>
                    </div>
                )
            }
        </div>
    }
    return (
        <>
            <section className='Navbar'>
                <div className='Navbar_inside '>

                    <div className='nabar_inside_child'>

                        <div className='navbar_inside_child2'>


                            {/* All categories with DropDown  */}
                            <div
                                className='allCategories_box '
                                onClick={() => handleDeopowntoggle("Allcategories")}

                            // onMouseLeave={() => handleDeopowntoggle("Allcategories")}
                            >
                                <div className="allCategories_box_inside">
                                    <BiMenu size={20} />
                                    <span >All Categories</span>
                                </div>


                                {/* DropDown Functionality  */}
                                {dropdownOpen.Allcategories && (
                                    <div className=" dropdown_functionality ">
                                        <ul>
                                        {
                                        categories.map((item, i) =>
                                            <a href={'/filter/' + item._id}><li className="li_links_dropdown">{item.name}</li>
                                            </a>
                                            )}
                                        </ul>

                                    </div>
                                )}
                            </div>

                            {/* Deals With DropDown */}
                            <div
                                onClick={() => handleDeopowntoggle("Deals")}
                                // onMouseLeave={() => handleDeopowntoggle("Deals")}
                                className="deals_dropdown_box">
                                <p className='font-roboto'>Deals</p>

                                {dropdownOpen.Deals && (
                                    <div className="dropdown_functionality">
                                        <ul>

                                            <li className="li_links_dropdown">XYZAAHHAJ</li>
                                            <li className="li_links_dropdown">XYZAAHHAJ</li>
                                            <li className="li_links_dropdown">XYZAAHHAJ</li>
                                            <li className="li_links_dropdown">XYZAAHHAJ</li>
                                            <li className="li_links_dropdown">XYZAAHHAJ</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {/* Search link with dropdown */}

                            {/* <div */}

                            {/* // onMouseEnter={() => handleDeopowntoggle("Deals")}
                                // onMouseLeave={() => handleDeopowntoggle("Deals")} */}
                            {/* className='relative ease-in-out duration-1000 group-hover:w-[300px] group-focus-within:w-full group-focus-within:bg-tranparent flex group-hover:bg-transparent items-center w-3 rounded-full p-1.5'> */}

                            {/* <button onSubmit={handleSubmit} className=" group-focus-within:visible ease-linear duration-500 text-white/40 group-hover:opacity-100 group-focus-within:opacity-100">
                                    <CiSearch size={25} className="text-white brightness-200" />
                                </button> */}

                            {/* <span>Search</span> */}
                            {/* <input type="search" name="search"
                                    placeholder="Search..."
                                    value={text}
                                    onChange={(e) => setText(e.target.searchTerm)}
                                    className="opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 invisible group-hover:visible group-focus-within:visible ease-in-out duration-700 rounded-full w-full h-9 py-4 pl-4 text-gray-700 bg-gray-100/20 leading-tight focus:outline-none focus:shadow-outline lg:text-sm text-xs"
                                /> */}

                            {/* </div> */}


                            <div className="navbar_cart">
                                <Link to={'/Cart'} className='flex' >
                                    <BsCart size={20} />
                                    <p >Carts</p>
                                </Link>
                            </div>


                        </div>
                        {/* Q2Q middle  logo */}
                        <div>
                            <div className="midlogo_navbar">
                                <Link to='/'> <img src={NavbarmiddleLogo} alt="navbar_middle_logo" /></Link>
                            </div>
                        </div>

                        <div className='navbar_inside_2'>

                            <div
                                className='wallet_navbar'>
                                <CiWallet size={20} />
                                <p className='font-roboto'>Wallet</p>
                            </div>



                            {loginButton}

                            <div
                                onClick={() => handleDeopowntoggle("Language")}
                                // onClick={handleDeopowntoggle("Language")}
                                // onMouseLeave={() => handleDeopowntoggle("Language")}
                                className='text-white  relative '>
                                <div className="flex items-center">
                                    <p className='font-roboto cursor-pointer'>English</p>
                                    <MdKeyboardArrowDown size={20} />
                                </div>

                                {dropdownOpen.Language && (
                                    <div className='dropdown_functionality_language' >
                                        <ul>
                                            <li className="li_links_dropdown">Bengali</li>
                                            <li className="li_links_dropdown">English</li>
                                            <li className="li_links_dropdown">Hindi</li>
                                            <li className="li_links_dropdown">Telugu</li>
                                            <li className="li_links_dropdown">Malayalam</li>
                                            <li className="li_links_dropdown">Turkish</li>
                                            <li className="li_links_dropdown">Urdu</li>
                                            <li className="li_links_dropdown">Korean</li>
                                        </ul>
                                    </div>
                                )}
                            </div>


                        </div>

                    </div>
                </div>
            </section >








            {/* Hamburger Menu */}

            < div className='z-50' >
                <div onClick={handleClick} className="hamburgerLinks_myaccount_box ">
                    {
                        toggle ?
                            < FaTimes onClick={() => setToggle(!toggle)} className='text-[#1F0A56] text-right text-3xl md:hidden block' />
                            :
                            <FaBars onClick={() => setToggle(!toggle)} className=' text-[#1F0A56] text-right text-3xl md:hidden block' />
                    }                </div>


                <ul className={`duration-300 md:hidden p-[20px] pb-[80px] gap-7 text-white fixed bg-gradient-to-r from-purple-800 to-purple-900 w-[200px] h-full top-0 
                ${toggle ? 'left-[0]' : 'left-[-100%]'}`
                }>
                    <li className="text-[18px] font-roboto border-b border-gray-800">All Categories</li>

                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyzs</p>


                    <li className="text-[18px] font-roboto border-b border-gray-800">Deals</li>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyz</p>
                    <p>xyzs</p>
                    <li className="text-[18px] font-roboto border-b border-gray-800">Carts</li>
                    <li className="text-[18px] font-roboto border-b border-gray-800">Wallet</li>
                    {/* <li className="text-[17px] font-roboto">English</li> */}
                    <li className="text-[18px] font-roboto border-b border-gray-800">My Account</li>

                    <Link to={'/overview'}><div className='hamburgerLinks_myaccount'>
                        <FaCubesStacked />
                        <p>Overview</p>
                    </div>
                    </Link>

                    <Link to={'/orderReturn'}>
                        <div className='hamburgerLinks_myaccount'>
                            <FaBoxOpen />
                            <p>Orders & Returns</p>
                        </div>
                    </Link>

                    <Link to={'/wishlist'}>
                        <div className='hamburgerLinks_myaccount'>
                            <CiHeart />
                            <p>Wishlist</p>
                        </div>
                    </Link>

                    <Link to={'/address'}>
                        <div className='hamburgerLinks_myaccount'>
                            <IoLocationOutline />
                            <p>Address</p>
                        </div>
                    </Link>

                    <Link to={'/savedCard'}>
                        <div className='hamburgerLinks_myaccount'>
                            <CiCreditCard2 />
                            <p>Saved Cards</p>
                        </div>
                    </Link>

                    <Link to={'/notification'}>
                        <div className='hamburgerLinks_myaccount'>
                            <CiBellOn />
                            <p>Notifications</p>
                        </div>
                    </Link>

                    <Link to={'/login'}>
                        <li className="text-[17px] font-roboto">Sign In</li>
                    </Link>

                    <Link to={'/signup'}>
                        <li className="text-[17px] font-roboto">Sign up</li>
                    </Link>

                    {/* <li className="text-[17px] font-roboto">English</li> */}



                </ul>
            </div >


        </>
    )
}
export default Navbar;