import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer';
import { CiDeliveryTruck } from 'react-icons/ci';
import { VscRefresh } from 'react-icons/vsc';
import { PiCurrencyKrwBold } from 'react-icons/pi';
import { GoPlus } from "react-icons/go";
import { RxMinus } from "react-icons/rx";
import '../css/discription.css';
import '../css/global.css';
import azito from '../assests/discriptionImage/p1.png';
import atizo from '../assests/discriptionImage/atozio.png';
import p2 from '../assests/discriptionImage/p2.png';
import p3 from '../assests/discriptionImage/p3.png';
import pw1 from "../assests/filterProductImage/pw1.webp";
import pw2 from "../assests/filterProductImage/pw2.webp";
import pw3 from "../assests/filterProductImage/pw3.webp";
import pw4 from "../assests/filterProductImage/pw4.webp";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import *as  CONSTANT from '../constant'


import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Discription = () => {


    // for quantity button
    const [quantity, setQuantity] = useState(1);
    const [productDetail, setProductDetail] = useState([{
        name: "",
        price: "",
        image: "",
        description: ""
    }]);
    const pid = useParams();
    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const [errorMessage, setErrorMessage] = useState('');
    // for acordian
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    //   for liked Heart icon

    const [likedCards, setLikedCards] = useState([false]);

    const handleLikeClick = (cardNumber) => {
        setLikedCards((prevLikedCards) => {
            const updatedLikedCards = [...prevLikedCards];
            updatedLikedCards[cardNumber] = !updatedLikedCards[cardNumber];
            return updatedLikedCards;
        });
    };
    const FETCH_SINGLE_ITEM_ON_LOAD = async () => {
        await axios.get(`${CONSTANT.baseUrl}/api/admin/get_single_item/${pid.id}`).then((description) => {
            console.log("response", description.data)

            setProductDetail(description.data.DATA);


        }).catch(err => {
            console.warn(err)
        })

    };
    const navigate = useNavigate();
    var cartbutton = "";
    if (sessionStorage.getItem("user_id") != null && sessionStorage.getItem("user_id") !== 'undefined') {
        cartbutton = <button className='_add_to_cart_btn ' onClick={() => addtocart()}>
            ADD TO CART
        </button>

    }
    else {
        cartbutton = <button onClick={() => navigate('/login')} class='loginButton' >Log In First</button>
    }
    const addtocart = async e => {

        var name_;
        if (quantity && pid.id) {

            let data = {
                quantity: quantity,
                price: productDetail.price,
                product_id: pid.id,
                user_id: sessionStorage.getItem("user_id"),
            }

            const res = await axios.post(`${CONSTANT.baseUrl}/api/addToCart`, data);
            // set the state of the user

            if (res.data.code == '200') {
                setTimeout(function () { navigate("/cart"); }, 1000);
            }
            else {
                setErrorMessage(res.data.MESSAGE);
            }


        }
        else {
            setErrorMessage("Please enter mandatory data!");
        }

    }
    useEffect(() => {
        FETCH_SINGLE_ITEM_ON_LOAD();
    }, []);


    return (
        <>
            <Navbar />
            <section className='main_section'>
                {/* for  header */}
                <div className="headerTextContainer_wrap">
                    <div className="headerTextContainer_inside_wrap">
                        <CiDeliveryTruck size={22} className="text-[#A000A0]" />
                        <p className="headerfilterText">
                            Next day delivery available on certain products
                        </p>
                    </div>
                    <div className="headerTextContainer_inside_wrap">
                        <VscRefresh size={21} className="text-[#A000A0]" />
                        <p className=" headerfilterText">No hassle 14 Day Returns</p>
                    </div>
                    <div className="headerTextContainer_inside_wrap">
                        <PiCurrencyKrwBold size={21} className="text-[#A000A0]" />
                        <p className=" headerfilterText">Unbeatable prices</p>
                    </div>
                </div>

                <div className='_product_dis_container'>
                    <img src={productDetail.image} alt='product' className='_dis_Image' />
                    <div className='_pro_sepci_main'>
                        <h1 className='_pro_text'>{productDetail.name}</h1>
                        <h1 className='_pro_price'>$ {productDetail.price}</h1>
                        <p className='_pro_dis'>{productDetail.description}</p>
                        <table className='table'>
                            <tbody className='tbody'>



                                <tr className='_pro_sepci_box'>
                                    <td className='_pro_title'>Delivery fee</td>
                                    <td className='_pro_specification'>$ 0</td>
                                </tr>
                            </tbody>
                        </table>

                        <div>
                            <h1 className='_quantity_text'>Quantity</h1>
                            <div className="_quantity_btn_box">
                                <button onClick={decreaseQuantity} className=''> <RxMinus /> </button>
                                <span className="px-3 text-[14px]">{quantity}</span>
                                <button onClick={increaseQuantity} className=''> <GoPlus /> </button>
                            </div>
                        </div>



                        {cartbutton}



                        <div className='_delivery_deetails_box'>

                            <h1 className='_delivery_Text'><VscRefresh size={22} className="text-[#A000A0]" />10 Days Easy Return |<u> Know More</u></h1>
                        </div>
                    </div>

                </div>

                {/* Acordian deetails */}
                <div className='_acordian_wrap'>
                    <div className='_acordian'>
                        <div >
                            <div className="_acordian_box " onClick={() => toggleAccordion(0)}>
                                <h1 className='acordian_heading'>Product Description</h1>
                                <div className="text-sm">{activeIndex === 0 ? '-' : '+'}</div>
                            </div>
                            {activeIndex === 0 && (
                                <p className='_acordian_content'>{productDetail.description}</p>
                            )}
                        </div>

                    </div>
                </div>

                {/* Recently Viwed */}



            </section>

            <Footer />
        </>
    )
}

export default Discription;