import { useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import { TbDots } from "react-icons/tb";
import *as  CONSTANT from '../constant'
import { HiArrowSmRight } from "react-icons/hi";
import { MdOutlineRemove } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import Product1 from "../assests/homeImage/productImage/product1.png";
import Product2 from "../assests/homeImage/productImage/product2.png";
import Product3 from "../assests/homeImage/productImage/product3.png";
import Product4 from "../assests/homeImage/productImage/product4.png";
import bankImage1 from "../assests/homeImage/productImage/bank1.png";
import bankImage2 from "../assests/homeImage/productImage/bank2.png";
import bankImage3 from "../assests/homeImage/productImage/bank3.png";
import Banner from "../assests/homeImage/dealsOftheImage/Mask group.png";
import Foxhub from "../assests/homeImage/dealsOftheImage/foxhub.png";
import Oracle from "../assests/homeImage/dealsOftheImage/oracle.png";
import axios from 'axios'
import product1 from "../assests/homeImage/shopByCategoriesImage/product1.png";
import product2 from "../assests/homeImage/shopByCategoriesImage/product2.png";
import product3 from "../assests/homeImage/shopByCategoriesImage/product3.png";
import product4 from "../assests/homeImage/shopByCategoriesImage/product4.png";
import product5 from "../assests/homeImage/shopByCategoriesImage/product5.png";
import product6 from "../assests/homeImage/shopByCategoriesImage/product6.png";
import product7 from "../assests/homeImage/shopByCategoriesImage/product7.png";
import product8 from "../assests/homeImage/shopByCategoriesImage/product8.png";
import product9 from "../assests/homeImage/shopByCategoriesImage/product9.png";
import product10 from "../assests/homeImage/shopByCategoriesImage/product10.png";
import product11 from "../assests/homeImage/shopByCategoriesImage/product11.png";
import product12 from "../assests/homeImage/shopByCategoriesImage/product12.png";
import product13 from "../assests/homeImage/shopByCategoriesImage/product13.png";
import product14 from "../assests/homeImage/shopByCategoriesImage/product14.png";
import product15 from "../assests/homeImage/shopByCategoriesImage/product15.png";

import skincareSaleImage from "../assests/homeImage/skincareSaleImage/skincareSaleImage.png";
import logolipsun from "../assests/homeImage/skincareSaleImage/logolipsum.png";

import handbages from "../assests/homeImage/alsolikeImage/handbags.png";
import bioOil from "../assests/homeImage/alsolikeImage/bioOil.png";
import casterOil from "../assests/homeImage/alsolikeImage/casterOil.png";
import liner from "../assests/homeImage/alsolikeImage/liner.png";
import simplyvital from "../assests/homeImage/alsolikeImage/simplyvital.png";
import antiCellilute from "../assests/homeImage/alsolikeImage/antiCellulite.png";

import argoOil from "../assests/homeImage/moreProductImage/argonOil.png";
import BeautyandSpa from "../assests/homeImage/moreProductImage/Beauty&SpaImage.png";
import BeautyandTreatment from "../assests/homeImage/moreProductImage/Beauty&Treatment.png";
import essiential from "../assests/homeImage/moreProductImage/essientalOil.png";
import hairOil from "../assests/homeImage/moreProductImage/hairOil.png";

import HairImage from "../assests/homeImage/bestSellerIcomponentImage/HairImage.png";
import clayMask from "../assests/homeImage/bestSellerIcomponentImage/clayMask.png";
import essenceWatar from "../assests/homeImage/bestSellerIcomponentImage/essenceWatar.png";
import CinyCrawford from "../assests/homeImage/bestSellerIcomponentImage/CinyCrawford.png";

import BeautyAndSpa from "../assests/homeImage/featuredProductImage/BeautyAndSpa.png";
import BeautyTreatment from "../assests/homeImage/featuredProductImage/BeautyTreatment.png";

import ArganOil from "../assests/homeImage/BestSellerAutoplayImage/ArganOil.png";
import Catepill from "../assests/homeImage/BestSellerAutoplayImage/Catephil.png";
import Iconinc from "../assests/homeImage/BestSellerAutoplayImage/Iconic.png";
import lakme from "../assests/homeImage/BestSellerAutoplayImage/lakme.png";
import Lotus from "../assests/homeImage/BestSellerAutoplayImage/Lotus.png";
import Powder from "../assests/homeImage/BestSellerAutoplayImage/Powder.png";
import Serum from "../assests/homeImage/BestSellerAutoplayImage/Serum.png";

import pro1 from "../assests/homeImage/RecommndedforyouImage/product1.png";
import pro2 from "../assests/homeImage/RecommndedforyouImage/product2.png";
import pro3 from "../assests/homeImage/RecommndedforyouImage/product3.png";

import Header from "./Header";
import Footer from "./Footer";
import "../css/home.css";
import "../css/global.css";

import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Parallax, Pagination, Navigation, Keyboard, Scrollbar, Autoplay } from "swiper/modules";

function Home() {
  const [homeBanner, setHomeBanner] = useState([{
    heading: "",
    sub_heading: "",
    image: "",
    description: ""
  }]);
  const [bankBanner, setBankBanner] = useState([{
    image1: "",
    image2: "",
    image3: "",
    description1: "",
    description2: "",
    description3: ""
  }]);
  const [dealBanner, setDealBanner] = useState([{
    image1: "",
    image2: "",
    image3: "",
    description1: "",
    description2: ""
  }]);
  const [saleBanner, setSaleBanner] = useState([{
    heading: "",
    image: ""
  }]);
  const [products, setProducts] = useState([{
    name: "",
    price: "",
    image: "",
  }]);
  const [shopCatProducts, setShopCatProducts] = useState([{
    catName: "",
  }]);
  const [shopLinkProducts, setShopLinkProducts] = useState([{
    title: "",
    image:"",
    link:""
  }]);
  
  const [randproducts, setRandProducts] = useState([{
    name: "",
    price: "",
    image: "",
  }]);
  const [featuredproducts, setFeaturedProducts] = useState([{
    name: "",
    price: "",
    image: "",
  }]);
  const [bestsellerproducts, setBestSellerProducts] = useState([{
    name: "",
    price: "",
    image: "",
  }]);
  useEffect(() => {
    fetchProducts();
    shopByCategory();
    shopByLink();
    fetchHomeBanner();
    fetchBankBanner();
    fetchDealBanner();
    fetchSaleBanner();
  }, []);
  const fetchProducts = async () => {
    let array = []
    let newarray = []
    let bestarray = []
    let featuredarray = []
    const data = {

    }
    const res1 = await axios.get(`${CONSTANT.baseUrl}/api/get_best_seller_items`, data);
    for (let item of res1.data.DATA) {
      if (item.name) {

        bestarray.push(item)
      }

    }
    const res2 = await axios.get(`${CONSTANT.baseUrl}/api/get_featured_items`, data);
    for (let item of res2.data.DATA) {
      if (item.name) {

        featuredarray.push(item)
      }

    }
    const res = await axios.get(`${CONSTANT.baseUrl}/api/get_item`, data);
    for (let item of res.data.DATA) {
      if (item.name) {

        array.push(item)
      }

    }
    featuredarray = featuredarray.slice(0, 4);
    newarray = array.sort(() => Math.random() - Math.random()).slice(0, 4);
    setRandProducts(newarray);
    setProducts(array);
    setFeaturedProducts(featuredarray);
    setBestSellerProducts(bestarray);
  };
  const shopByCategory = async () => {
    let array = []
    const data = {

    }

    const res = await axios.get(`${CONSTANT.baseUrl}/api/get_shop_cat`, data);
    for (let item of res.data.DATA) {
      if (item.catName) {

        array.push(item)
      }

    }

    setShopCatProducts(array);

  };
  const shopByLink = async () => {
    let array = []
    const data = {

    }

    const res = await axios.get(`${CONSTANT.baseUrl}/api/get_shop_link`, data);
    for (let item of res.data.DATA) {
      if (item.title) {

        array.push(item)
      }

    }

    setShopLinkProducts(array);

  };
  const fetchHomeBanner = async () => {
    await axios.get(`${CONSTANT.baseUrl}/api/admin/get_home_banner/`).then((detail) => {
      console.log("response", detail.data)

      setHomeBanner(detail.data.DATA);


    }).catch(err => {
      console.warn(err)
    })
  };
  const fetchBankBanner = async () => {
    await axios.get(`${CONSTANT.baseUrl}/api/admin/get_bank_banner/`).then((detail) => {
      console.log("response", detail.data)

      setBankBanner(detail.data.DATA);


    }).catch(err => {
      console.warn(err)
    })
  };
  const fetchSaleBanner = async () => {
    await axios.get(`${CONSTANT.baseUrl}/api/admin/get_sale_banner/`).then((detail) => {
      console.log("response", detail.data)

      setSaleBanner(detail.data.DATA);


    }).catch(err => {
      console.warn(err)
    })
  };
  const fetchDealBanner = async () => {
    await axios.get(`${CONSTANT.baseUrl}/api/admin/get_deal_banner/`).then((detail) => {
      console.log("response", detail.data)

      setDealBanner(detail.data.DATA);


    }).catch(err => {
      console.warn(err)
    })
  };



  // swiper slide crasousel





  return (
    <>
      <Header />

      {/* Product */}

      <section className="productContainer">

        <div className="productInsideContainerFrist">
          <section className="sectionContainer ">
            {
              randproducts.map((item, i) =>
                <Link to={'/discription/' + item._id}>
                  <div className="productBox ">

                    <img src={item.image} alt="product-image" className="w-full h-full" />

                    <div className="productBoxInside">
                      <div className="TextBox">
                        <p className="pro-name-text">{item.name}</p>
                      </div>
                    </div>

                  </div>
                </Link>

              )}
          </section>


        </div>

        {/* for mobile */}
        <section className="md:hidden block ">
          <Swiper
            slidesPerView={3}
            centeredSlides={false}
            slidesPerGroupSkip={2}
            grabCursor={true}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              769: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
            }}
            // scrollbar={true}
            // navigation={true}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 4000, // Delay between slides in milliseconds
              disableOnInteraction: true,
            }}
            modules={[Keyboard, Scrollbar, Navigation, Pagination, Autoplay]}
            className="mySwiper"

          >
            <div className=" productInsideContainerFrist">

              <section className="sectionContainer ">
                {
                  randproducts.map((item, i) =>
                    <Link to={'/discription/' + item._id}>
                      <SwiperSlide  >
                        <div className="productBox">

                          <img src={item.image} alt="product-image" className="w-full h-full" />

                          <div className="productBoxInside">
                            <div className="TextBox">
                              <p className="pro-name-text">{item.name}</p>
                            </div>
                          </div>

                        </div>
                      </SwiperSlide>
                    </Link>

                  )}
              </section>

            </div>
          </Swiper>
        </section>



        <div className="bankDiscountContainer">

          <div className="BankStipBox">
            <div className="imageBox1 ">
              <img src={bankBanner.image1} className="sinhanBankImage" />
            </div>
            <div className="verticleLine"></div>
            <div className="mt-1">
              <p className="creditCardText">{bankBanner.description1}</p>
              <p className="T_C">*T&C Apply</p>
            </div>
          </div>

          <div className="BankStipBox">
            <div className="imageBox2">
              <img src={bankBanner.image2} className=" wooriBankImage" />
            </div>

            <div className="verticleLine"></div>

            <div className="mt-1">
              <p className="creditCardText">{bankBanner.description2}</p>
              <p className="T_C">*T&C Apply</p>
            </div>
          </div>

          <div className="BankStipBox">
            <div className="imageBox3 ">
              <img src={bankBanner.image3} className="hanaBankImage" />
            </div>
            <div className="verticleLine"></div>

            <div className="mt-1">
              <p className="creditCardText">{bankBanner.description3}</p>
              <p className="T_C">*T&C Apply</p>
            </div>
          </div>
        </div>
      </section >

      {/* Deals of the day product */}

      <section section className="DealContainer" >
        <h1 className="headingText">DEALS OF THE DAY</h1>

        <div className="dealsInsideContainer">
          <img className="DealBanner" src={dealBanner.image1} />

          <div className="dealBannerInsideContainer ">
            <div className="BoxCream">
              <h1 className="cellcoveryCreamText">{dealBanner.description1}</h1>
              <h1 className="flatOff">{dealBanner.description2}</h1>
            </div>

            <div className="ImageBoxContaiiner">
              <div className="InsideImageBox ">
                <img className="foxhubImage" src={dealBanner.image2} />
                <p className="line"></p>
                <img className=" oracleImage " src={dealBanner.image3} />
              </div>

            </div>
          </div>
        </div>
      </section >



      {/* Shop by categories */}

      <section className="md:min-w-[1000px] md:max-w-[1460px] md:ml-8 md:mr-2 " >
        <h1 className="headingText">SHOP BY CATEGORY</h1>
        <div className="ShopContainer ">
          {
            shopCatProducts.map((item, i) =>
              <Link to={'/filter/' + item.catId}>
                <div className="productImage">
                  <img src={item.image} alt="product-image" className="image-product" />
                  <div className="productImageTextBox ">
                    <div className="shopProductTextBox group relative  overflow-hidden flex flex-col items-center justify-center">
                      <h1 className="shopprodductName">{item.catName}</h1>
                      <p className="shopNow">SHOP NOW</p>
                      <div class="absolute inset-0 h-full w-full scale-0 transition-all duration-300 group-hover:scale-100 group-hover:bg-white/30"></div>
                    </div>
                  </div>
                </div>
              </Link>
            )}
        </div>
      </section >

      {/* Skincare Sales */}

      <section section className=" skinCareBox " >
        <div className="skinCareBoxInside">
          <h1 className="skincareHeaderText">SKINCARE SALE</h1>

          <div className="skincareTextbox ">
            {/* Writing text */}
            <div className=" skincareTextBox">
              <div className="skinCareTextBox2 ">
                <h2 className="skinCareMainHeaderText">
                  {saleBanner.heading}
                </h2>
              </div>

            </div>

            {/* Add image */}
            <div>
              <img className="skinCareImage" src={saleBanner.image} />
            </div>
          </div>
        </div>


      </section >

      {/* You might also like */}

      <section className="alsoLikeContainer">
        <div>
          <h1 className="headingText">YOU MIGHT ALSO LIKE</h1>
        </div>

        <div className="sloLikeContainerTwo">

        {
            shopLinkProducts.map((item, i) =>
          <div className=" asloLikeProductBox">
            <Link to={item.link}><div className="alsoLikeImageBox">
              <img
                src={item.image}
                className=" pt-[27px] pb-[26px] pl-[40px] pr-[40px]   "
              />
            </div>
            </Link>
            <h1 className="alsoLikeProductName">{item.title}</h1>
          </div>
          )}
         


        </div>
      </section>

      {/* More Products */}



      {/* Best seller  */}

      <section className="md:block hidden">
        <h1 className="headingText">FEATURED</h1>
        <div className="bestSellerContainer">
          <div className=" bestSellerProductContainer">
            {
              featuredproducts.map((item, i) =>
                <div className="bestSellerProductBox">
                  <div className="md:w-[290px]">
                    <div className="bestSellerImageBox">
                      <img
                        src={item.image}
                        alt="productImage"
                        className="bestSellerImage1"
                      />
                    </div>

                    <div className="flex flex-col gap-[10px]">
                      <h1 className="bestSellerProductName">{item.name}</h1>
                      <div className="flex gap-[5px]">
                        <h1 className="bestSellerProductPrice">$ {item.price}</h1>
                      </div>
                      <Link to={'/discription/' + item._id}><button className="button"> VIEW PRODUCT </button></Link>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>



      {/* for mobile */}


      <section className="md:hidden">
        <h1 className="headingText">FEATURED</h1>
        <div className="bestSellerContainer">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            <div className=" bestSellerProductContainer">
              {
                featuredproducts.map((item, i) =>
                  <SwiperSlide>
                    <div className="bestSellerProductBox">
                      <div className="md:w-[290px] ">
                        <div className="bestSellerImageBox">
                          <img
                            src={item.image}
                            alt="productImage"
                            className="bestSellerImage1"
                          />
                        </div>

                        <div className="flex flex-col gap-[10px]">
                          <h1 className="bestSellerProductName">{item.name}</h1>
                          <div className="flex gap-[5px]">
                            <h1 className="bestSellerProductPrice">$ {item.price}</h1>
                          </div>
                          <Link to={'/discription/' + item._id}><button className="button"> VIEW PRODUCT </button></Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
            </div>
          </Swiper>
        </div>
      </section>



      {/* Best seller Auto play owl carousel */}

      <section className="carouselContainer">
        <h1 className="carouselHeadingText">BEST SELLERS</h1>

        <Swiper
          speed={600}
          parallax={true}
          slidesPerView={2.1}
          // spaceBetween={10}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          watchSlidesProgress={true}
          modules={[Pagination, Parallax, Navigation]}
          navigation={true}
          className="mySwiper"
          breakpoints={{
            // Breakpoint for laptops and larger screens
            1024: {
              slidesPerView: 6, // Number of slides per view for laptops
            },
          }}
        >
          <div className="carouselContainerInside">
            <div className="">
              {
                bestsellerproducts.map((item, i) =>
                  <SwiperSlide>
                    <div id="item1" className="carouselProductMainBox">
                      <div className="carouselProductBox">
                        <Link to={'/discription/' + item._id}>
                          <div className="group flex justify-center text-center relative overflow-hidden rounded-md cursor-pointer">
                            <img src={item.image} className=" h-[215px] object-cover ease-in-out duration-500 group-hover:rotate-6 group-hover:scale-125" alt="argan oil" />
                          </div>

                        </Link>
                      </div>
                      <div>
                        <h1 className="productSpecification">
                          {item.name}
                        </h1>

                        <div className="flex justify-between">
                          <div className="flex items-center gap-1 ">
                            <p className="productPrice">${item.price}</p>
                          </div>
                          {/* <div className="carouselProductSpecificationBox">
                            <p className="productReview">4.5</p>
                            <p className="starEntity">&#11088;</p>
                            <p className="productReviewInNumber">/5(4.3k)</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}

            </div>

          </div>
        </Swiper>
      </section>



      {/* Recomnded for you */}



      {/* Footer */}
      <Footer />
    </>
  );
}
export default Home;
