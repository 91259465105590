import { Route, Router, Routes , useLocation} from "react-router-dom";
import Home from "./component/Home";
import LoginPage from './component/LoginPage';
import Signup from './component/Signup';
import Filterpage from './component/Filterpage';
import Discription from "./component/Discription";
import Cart from "./component/Cart";
import Checkout from "./component/Checkout";
import Payment from "./component/Payment";
import Myaccount from "./component/profile/Myaccount";
import OrderDeetails from "./component/orderDeetails/OrderDeetails";
import Wishlist_nothing from "./component/profile/Wishlist_nothing";
import Wishlist from "./component/profile/Wishlist";
import ProfileOverview from "./component/profile/ProfileOverview";
import OrderAndReturn from "./component/profile/OrderAndReturn";
import Saved_add from "./component/profile/Saved_add";
import Saved_card from "./component/profile/Saved_card";
import Notification from "./component/profile/Notification";
import React, { useEffect } from 'react';

function App() {

const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/filter/:id" element={<Filterpage />} />
        <Route path="/discription/:id" element={<Discription />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/paymentCheckout" element={<Payment />} />
        <Route path="/myaccount" element={<Myaccount />} />
        <Route path="/order_deetails" element={<OrderDeetails />} />
        <Route path="/nothingToShow" element={<Wishlist_nothing />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/overview" element={<ProfileOverview />} />
        <Route path="/orderReturn" element={<OrderAndReturn />} />
        <Route path="/address" element={<Saved_add />} />
        <Route path="/savedCard" element={<Saved_card />} />
        <Route path="/notification" element={<Notification />} />

      </Routes>

    </>
  );
}

export default App;
