import React from 'react'
import '../../css/My_account/notification.css';
import { MdOutlineTextsms } from "react-icons/md";
import Navbar from '../Navbar';
import Footer from '../Footer';

import Switch from '@mui/material/Switch';
const label = { inputProps: { 'aria-label': 'Switch demo' } };


const Notification = () => {
    return (
        <>
            <div className='md:hidden block'>
                <Navbar />
            </div>

            <div className='notification_text'>
                <p className='text-left text-[#939393] text-[14px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
            </div>

            <div className='sms_box_notification'>

                <div className='flex justify-center items-center'>
                    <MdOutlineTextsms size={25} />
                    <h2 className='text-[#000000] font-roboto text-[18px] font-semibold'>SMS</h2>
                </div>

                <div>
                    <Switch {...label} defaultChecked />
                </div>

            </div>

            <div className='sms_box_notification'>

                <div className='flex justify-center items-center'>
                    <MdOutlineTextsms size={25} />
                    <h2 className='text-[#000000] font-roboto text-[18px] font-semibold'>Lorem Lipsum</h2>
                </div>

                <div>
                    <Switch {...label} defaultChecked />
                </div>

            </div>

            <div className='md:hidden block mt-[10px]'>
                <Footer />
            </div>

        </>
    )
}

export default Notification;