import React from 'react'
import '../../css/My_account/saved_add.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Saved_add = () => {
    return (

        <>
            <div className='md:hidden block'>
                <Navbar />
            </div>

            <div className='saved_add_container'>
                <h1 className='saved_add_cos_name'>Jhon doe</h1>
                <div >
                    <p className='saved_add_deetails'> Near Paramount Plaza Gill</p>
                    <p className='saved_add_deetails'>opposite Hyundai Motors</p>
                    <p className='saved_add_deetails'> Sejong, 112254</p>
                    <p className='saved_add_deetails'> Ph. +82 9568745621</p>
                </div>
                <div className='w-full h-[2px] bg-[#E5E5E5] mt-[25px]'></div>
                <div className='edit_btn_add'>
                    <button className='saved_delete_btn'>Delete</button>
                    <button className='edit_saved_add_btn'>Edit adddress</button>
                </div>
            </div>

            <div className='saved_add_container'>
                <div className='add_new_add'>
                    <button className='edit_saved_add_btn'>Add New Address</button>
                </div>

            </div>

            <div className='md:hidden block mt-3'>
                <Footer />
            </div>
        </>

    )
}

export default Saved_add