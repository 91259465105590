import { useState, useEffect } from "react";
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { MdArrowBackIosNew } from 'react-icons/md';
import { TbDots } from 'react-icons/tb';

import { HiArrowSmRight } from 'react-icons/hi';
import Navbar from "./Navbar";
import axios from 'axios'
import *as  CONSTANT from '../constant'
import '../css/header.css';
import banner from '../assests/homeImage/headerImage/banner.png';

function Header() {
    const [homeBanner, setHomeBanner] = useState([{
        heading: "",
        sub_heading: "",
        image: "",
        description: ""
    }]);
    const slides = [
        {
            url: `${require('../assests/homeImage/headerImage/banner.png')}`
        },
        {
            url: `${require('../assests/homeImage/RecommndedforyouImage/product2.png')}`
        },

    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    const [dropdownOpen, setDropdownopen] = useState({
        Allcategories: false,
        Deals: false,
        Search: false,
        Carts: false,
        Wallet: false,
        Signin: false,
        Language: false
    })




    const prevSlide = () => {
        const isFristSlide = currentIndex === 0;
        const newIndex = isFristSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);

    };
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)

    }

    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    useEffect(() => {
        fetchHomeBanner();
    }, []);

    const fetchHomeBanner = async () => {
        await axios.get(`${CONSTANT.baseUrl}/api/admin/get_home_banner/`).then((detail) => {
            console.log("response", detail.data)

            setHomeBanner(detail.data.DATA);


        }).catch(err => {
            console.warn(err)
        })
    };






    // line break 

    function insertLineBreaks(text, wordsPerLine) {
        const words = text.split(' ');
        const lines = [];
        let line = '';

        for (let i = 0; i < words.length; i++) {
            line += words[i] + ' ';
            if ((i + 1) % wordsPerLine === 0 || i === words.length - 1) {
                lines.push(line.trim());
                line = '';
            }
        }

        return lines.join('<br />');
    }

    const homeBannerHeading = `${homeBanner.heading}`
    const wordsPerLine = 3; // Adjust this value as needed
    const formattedText = insertLineBreaks(homeBannerHeading, wordsPerLine);


    return (
        <>


            <div className="max-w-[1550px] mx-auto w-full md:h-[700px] h-[200px] m-auto relative group">

                <img src={homeBanner.image} alt="Background Image" class="w-full h-full object-cover" />

                <div className="absolute top-0 left-0 w-full h-full " >

                    {/* navbar */}
                    <Navbar />
                    {/* header Text */}
                    <div >
                        <div className="header_text_container">
                            <div className="header_text-container_2">
                                {/* <p className="header_textP ">{homeBanner.heading} </p> */}
                                <p className="header_textP -tracking-2" dangerouslySetInnerHTML={{ __html: formattedText }}></p>
                                <div className="uptoTextContainer ">
                                    <div className="upto">
                                        <h1 className="upti_h1">{homeBanner.sub_heading}</h1>
                                    </div>
                                    {/* <p className="divtext">{homeBanner.description}</p> */}
                                </div>
                            </div>
                            {/* <div className="md:w-[10px] md:h-[400px]">

                            </div> */}
                        </div>


                    </div>


                    <div className='lineContainer'>
                        <div className='lineContainerInside'>
                            <p className='lineText'>{homeBanner.description}</p>
                            <button><HiArrowSmRight size={40} /></button>
                        </div>
                        <div className="w-full bg-white h-[1.5px]">

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Header;