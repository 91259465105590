import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import '../css/checkout.css';
import { useState,useEffect } from 'react';
import *as  CONSTANT from '../constant'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';


const Checkout = () => {

    // for switching form  home and office
    const [currentForm, setCurrentForm] = useState('home');

    const showForm = (form) => {
        setCurrentForm(form);
    };


    // for count input character limits
    const [text, setText] = useState('');
    const maxLength = 100;

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // Truncate the input to the maxLength
        if (inputValue.length <= maxLength) {
            setText(inputValue);
            setAddReply({ ...addreply, [event.target.name]: inputValue});
        }
    };

    // returning back page
   const navigate = useNavigate();
   // const handleCancelClick = () => {
     //   navigate(-1);
    //};
    const [addreply, setAddReply] = useState({
    first_name: "",
    last_name: "",
    landmark: "",
    city: "",
    flat: "",
    state: "",
    pincode: "",
    phone: ""
  });
    const { first_name, last_name,landmark,city,flat,state,pincode,phone} = addreply;
  const onInputChange = e => {
    setAddReply({ ...addreply, [e.target.name]: e.target.value });
  };
  const [user, setUser] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [userDetail, setUserDetail] = useState([{
        first_name: "",
        last_name: "",
        landmark: "",
        city: "",
        flat: "",
        state: "",
        pincode: "",
        phone: "",
    }]);
    const FETCH_USER_DETAIL_ON_LOAD=async ()=>{
    if(sessionStorage.getItem('order_id')){
    await axios.get(`${CONSTANT.baseUrl}/api/fetchOrderDetail/${sessionStorage.getItem('order_id')}`).then((detail) => {
              console.log("response", detail.data)
              setAddReply(detail.data.ORDER_INFO[0]);


          }).catch(err => {
              console.warn(err)
          })
    }
    else{

        await axios.get(`${CONSTANT.baseUrl}/api/fetchUserDetail/${sessionStorage.getItem('user_id')}`).then((detail) => {
              console.log("response", detail.data)
              setAddReply(detail.data.USER_INFO[0]);


          }).catch(err => {
              console.warn(err)
          })
    
         
    }
    };
    
    const handleSubmit = async e => {
        e.preventDefault();
        if (first_name && last_name && landmark && city && flat && state && pincode && phone) {
    
      let data = {
        first_name: addreply.first_name,
        last_name: addreply.last_name,
        landmark: addreply.landmark,
        city: addreply.city,
        flat: addreply.flat,
        state: addreply.state,
        pincode: addreply.pincode,
        phone: addreply.phone,
        user_id: sessionStorage.getItem('user_id'),
        amount: sessionStorage.getItem('cartTotal'),
      }
        
      const res = await axios.post(`${CONSTANT.baseUrl}/api/createOrder`, data);
      // set the state of the `
      if(res.data.code=='200')
      {      
      // store the user in localStorage
      sessionStorage.setItem('order_id', res.data.DATA.Order_id)
      console.log(res.data)
      setTimeout(function () { navigate("/paymentCheckout"); }, 1000);
      }
      else
      {
      setErrorMessage(res.data.MESSAGE);
      }
      
      
      }
     else {
    setErrorMessage("Please enter mandatory data!");
    }
    }

    useEffect(() => {
    if(!sessionStorage.getItem('user_id'))  {
    navigate('/');
    }
    FETCH_USER_DETAIL_ON_LOAD();
  }, []);
    return (

        <>
            <Navbar />

            <section className='main_wrap'>
                <div className='main_child_wrap'>
                    <div className='child_wrap'>
                        <h1 className='checkout_heading'>Checkout</h1>

                        <div className='boxes_main_wrap'>
                            <div className='child_box_frist'>
                            {errorMessage && (
                       <p className="error"> {errorMessage} </p>
                     )}
                                <h1 className='add_text'>Add Shipping Address</h1>
                                <form className='' onSubmit={handleSubmit}>
                                {/* Home Address Form */}
                                {currentForm === 'home' && (
                                
                                    <div className='main_input_box'>
                                        <div className='_input_box_frist'>
                                            <div className='_input_box_frist_inside'>
                                                <input onChange={e => onInputChange(e)} className='input_name' type='text' placeholder='First Name (Required)*' name="first_name" value={first_name} /> 
                                                <input onChange={e => onInputChange(e)}  value={last_name} className='input_name' type='text' placeholder='Last  Name (Required)*' name="last_name" />
                                            </div>
                                            <input onChange={e => onInputChange(e)} className='input_Pincode' value={city}  name="city" type='text' placeholder='City (Required)*' />
                                            <input onChange={e => onInputChange(e)} className='input_Pincode' value={state} name="state" type='text' placeholder='State (Required)*' />
                                            <input value={pincode} onChange={e => onInputChange(e)} className='input_Pincode' name="pincode" type='tel' placeholder='Pincode (Required)*' />

                                        </div>
                                        <div className='_input_box_secend'>
                                            <input onChange={e => onInputChange(e)} className='input_Pincode' name="landmark" value={landmark} type='text' placeholder='Landmark (Required)*' />
                                            <div className=''>
                                                <textarea  className='textarea_class' value={flat}
                                                    onChange={handleInputChange} name="flat"
                                                    placeholder={`Flat/House No., Floor,Building/Company/ApartmentName, Road/Street Name, Area, Colony(Required)* (limit ${maxLength} characters)`}>
                                                </textarea>
                                                <p className='character_length'>{text.length} / {maxLength} characters</p>
                                            </div>
                                            <input value={phone} onChange={e => onInputChange(e)} className='input_Pincode' name="phone" type='text' placeholder='+91  Phone / Mobile Number (Required)*' />


                                        </div>
                                    </div>
                                    
                                )}

                                {/* Office Address Form */}
                                {currentForm === 'office' && (
                                    <div className='main_input_box'>
                                        <div className='_input_box_frist'>
                                            <div className='_input_box_frist_inside'>
                                                <input className='input_name' type='text' placeholder='First Name (Required)*' />
                                                <input className='input_name' type='text' placeholder='Last  Name (Required)*' />
                                            </div>
                                            <input className='input_Pincode' type='tel' placeholder='office City (Required)*' />
                                            <input className='input_Pincode' type='tel' placeholder='office State (Required)*' />
                                            <input className='input_Pincode' type='tel' placeholder='office Landmark (Required)*' />

                                        </div>
                                        <div className='_input_box_secend'>
                                            <input className='input_Pincode' type='tel' placeholder='Office Names' />
                                            <div className=''>
                                                <textarea className='textarea_class' value={text}
                                                    onChange={handleInputChange}
                                                    placeholder={`Flat/House No., Floor,Building/Company/ApartmentName, Road/Street Name, Area, Colony(Required)* (limit ${maxLength} characters)`}>
                                                </textarea>
                                                <p className='character_length'>{text.length} / {maxLength} characters</p>
                                            </div>
                                            <input className='input_Pincode' type='tel' placeholder='+82  Phone / Mobile Number (Required)*' />


                                        </div>
                                    </div>
                                )}

                                <button type='button' onClick={() => showForm('office')}></button>
                                <button type='button' onClick={() => showForm('home')}></button>
                                

                                <div className='line_horizental'></div>

                                <div className='main_btn_box'>
                                    <div className='btn_box_'>
                                       <Link to={'/Cart'}>
                                                <button className='_cancel_btn'>Cancel</button>
                                            </Link>
                                        
                                        
                                            <button type="submit" className='_save_btn'>Save & Continue</button>
                                       
                                    </div>
                                </div>
                                </form>

                            </div>
                            {/* total payable box */}
                            <div className='total_Payable_main'>

                                <div className='table_body'>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Bag Total</td>
                                        <td className='table_data_text'>$ {sessionStorage.getItem('cartTotal')}</td>
                                    </tr>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Delivery Fee</td>
                                        <td className='table_data_text'>$ 0</td>
                                    </tr>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Bag Subtotal</td>
                                        <td className='table_data_text'>$ {sessionStorage.getItem('cartTotal')}</td>
                                    </tr>
                                    

                                </div>

                                <div className='_sub_total_box'>
                                    <h1 className='total_price'>Total Payable</h1>
                                    <h1 className='total_price_number'>$ {sessionStorage.getItem('cartTotal')}</h1>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>

            </section>

            <Footer />

        </>

    )
}

export default Checkout