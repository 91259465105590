import Navbar from "./Navbar";
import { CiDeliveryTruck } from "react-icons/ci";
import { VscRefresh } from "react-icons/vsc";
import { PiCurrencyKrwBold } from "react-icons/pi";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import hairDyeImage from "../assests/page1_Images/BlackCombHairDyeImage.png";
import { useState, useEffect } from "react";
import "../css/filterPage.css";
import Footer from "./Footer";
import pw1 from "../assests/filterProductImage/pw1.webp";
import pw2 from "../assests/filterProductImage/pw2.webp";
import pw3 from "../assests/filterProductImage/pw3.webp";
import pw4 from "../assests/filterProductImage/pw4.webp";
import pw5 from "../assests/filterProductImage/pw5.webp";
import pw6 from "../assests/filterProductImage/pw6.webp";
import pw7 from "../assests/filterProductImage/pw7.webp";
import pw8 from "../assests/filterProductImage/pw8.webp";
import pw9 from "../assests/filterProductImage/pw9.webp";
import pw10 from "../assests/filterProductImage/pw10.webp";
import pw11 from "../assests/filterProductImage/pw11.webp";
import pw12 from "../assests/filterProductImage/pw12.webp";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import *as  CONSTANT from '../constant'
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

function Filterpage({ product }) {
  const pid = useParams();
  const [isSelect, setIsSelect] = useState({
    ProductType: false,
    Brand: false,
    Material: false,
    Discount: false,
    ColorFamily: false,
    Fabric: false,
    Pattern: false,
    Price: false,
    SheetType: false,
    Ideal: false,
    Size: false,
  });
  const [products, setProducts] = useState([{
    name: "",
    price: "",
    image: "",
  }]);
  const fetchProducts = async () => {
    let array = []
    const data = {

    }

    const res = await axios.get(`${CONSTANT.baseUrl}/api/productListAsCategoryWeb/${pid.id}`, data);
    for (let item of res.data.DATA) {
      if (item.name) {

        array.push(item)
      }

    }

    setProducts(array);

  };
  useEffect(() => {
    fetchProducts();
  }, []);
  const handleClick = () => {
    setIsSelect(!isSelect);
  };
  const handleDropToggle = (link) => {
    setIsSelect({ ...isSelect, [link]: !isSelect[link] });
  };

  //   for liked Heart icon

  const [likedCards, setLikedCards] = useState([false]);

  const handleLikeClick = (cardNumber) => {
    setLikedCards((prevLikedCards) => {
      const updatedLikedCards = [...prevLikedCards];
      updatedLikedCards[cardNumber] = !updatedLikedCards[cardNumber];
      return updatedLikedCards;
    });
  };

  // for mobile responsive filter
  const [isOpen, setIsOpen] = useState(false);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  }


  // for go to onclick product discription page
  const navigate = useNavigate();
  const handleCardClick = () => {
    // Navigate to the product description page when the card is clicked
    navigate(`/discription`);

    // navigate(`/discription${productId}`);
    // need to give product like this see above comment
  };


  return (
    <>
      <section className="_filter_page">
        <div className="">
          <div className="filterNavbar">
            <Navbar />
          </div>


          {/* for  header */}
          <div className="headerTextContainer_wrap">
            <div className="headerTextContainer_inside_wrap">
              <CiDeliveryTruck size={22} className="text-[#A000A0]" />
              <p className="headerfilterText">
                Next day delivery available on certain products
              </p>
            </div>
            <div className="headerTextContainer_inside_wrap">
              <VscRefresh size={21} className="text-[#A000A0]" />
              <p className=" headerfilterText">No hassle 14 Day Returns</p>
            </div>
            <div className="headerTextContainer_inside_wrap">
              <PiCurrencyKrwBold size={21} className="text-[#A000A0]" />
              <p className=" headerfilterText">Unbeatable prices</p>
            </div>
          </div>


        </div>

        {/* filter by Range =======================================*/}


        {/* sidebar filter ===========================================*/}







        {/* for desktop */}

        {/* product section============================================ */}


        <div className="_Product_Card_Container_dis_">
          {/* Product frist */}
          {
            products.map((item, i) =>
              <Link to={'/discription/' + item._id}>
                <div className="flex flex-col">
                  <div className="Product_Container_wrap-filter">
                    <div className="">
                      <img className="Product_Image" src={item.image} alt="product" />
                    </div>
                  </div>
                  <div className="p-3 border border-gray-500 w-[291px] min-h-[100px] h-[100%] rounded-bl-xl rounded-br-xl">
                    <h2 className="_Product_Title">{item.name}</h2>
                    <div className="flex gap-1">
                      <p className="_ProductPrice">$ {item.price}</p>
                    </div>
                  </div>
                </div>

              </Link>
            )}

        </div>





        {/* for mobile responsive */}
        <section className="md:hidden block w-full md:mb-[50px] mb-4 mt-4">
          <Swiper
            slidesPerView={2.8}
            // spaceBetween={30}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Pagination, Autoplay]}
          >
            <div className="_Product_Card_Container_dis_">
              {/* Product frist */}
              {
                products.map((item, i) =>
                  <Link to={'/discription/' + item._id}>
                    <SwiperSlide>
                      <div className="flex flex-col">
                        <div className="Product_Container_wrap-filter">
                          <div className="">
                            <img className="Product_Image" src={item.image} alt="product" />
                          </div>
                        </div>
                        <div className="p-1 md:w-[291px] w-[120px] min-h-[100px] h-[100%] rounded-bl-xl rounded-br-xl">
                          <h2 className="_Product_Title">{item.name}</h2>
                          <div className="flex gap-1">
                            <p className="_ProductPrice">$ {item.price}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                  </Link>
                )}

            </div>
          </Swiper>
        </section>









        {/* Show more */}






      </section>
      {/* Footer */}
      <Footer />
    </>
  );
}

export default Filterpage;
