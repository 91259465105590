import React from 'react'
import '../../css/My_account/wishlist.css';
import { useState } from 'react';
import { VscTrash } from "react-icons/vsc";

import pro1 from '../../assests/filterProductImage/pw10.webp';
import pro2 from '../../assests/filterProductImage/pw3.webp';
import pro3 from '../../assests/filterProductImage/pw6.webp';
import pro4 from '../../assests/filterProductImage/pw8.webp';
import pro5 from '../../assests/discriptionImage/p2.png';
import pro6 from '../../assests/discriptionImage/p3.png'
import Navbar from '../Navbar';
import Footer from '../Footer';


const Wishlist = () => {
    const initialProducts = [
        { id: 1, name: 'Product 1', price: 20, image: `${pro1}` },
        { id: 2, name: 'Product 2', price: 320, image: `${pro2}` },
        { id: 3, name: 'Product 3', price: 440, image: `${pro3}` },
        { id: 4, name: 'Product 4', price: 50, image: `${pro5}` },
        { id: 5, name: 'Product 5', price: 900, image: `${pro6}` },
        // Add more products as needed
    ];

    const [wishlist, setWishlist] = useState(initialProducts);

    const handleRemoveFromWishlist = (productId) => {
        const updatedWishlist = wishlist.filter((product) => product.id !== productId);
        setWishlist(updatedWishlist);
    };

    return (
        <>
            <div className='md:hidden block'>
                <Navbar />
            </div>

            <div >
                <h1 className='wislist_heading'>Wishlist</h1>
                <div className='wishlist_main_wrap'>
                    {wishlist.map((product) => (
                        <div className="product-card" key={product.id}>
                            <img src={product.image} alt={product.name} className="product-image" />
                            <div className="product-info">
                                <h2 className="product-name">{product.name}</h2>
                                <p className="product-price">${product.price}</p>
                                <button className="remove-button" onClick={() => handleRemoveFromWishlist(product.id)}>
                                    <VscTrash size={20} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='md:hidden block'>
                <Footer />
            </div>
        </>

    );
};

export default Wishlist;
