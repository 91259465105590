import React from 'react'
import '../../css/orderDeetails/orderDeetails.css';
import Navbar from '../Navbar';
import { FaBoxOpen } from "react-icons/fa";
import pro2 from '../../assests/homeImage/alsolikeImage/bioOil.png';


const OrderDeetails = () => {
    return (
        <>

            <Navbar />
            <section className='od_deetail_wrap'>
                <div className='flex gap-[24px]'>

                    <div className='order_deetails_wrap'>
                        <div className='delivery_deetail_wrap'>
                            <div className='delivery_deetail_wrap_child'>
                                <div >
                                    <div className='flex items-center gap-2'>
                                        <FaBoxOpen size={20} className='text-[#878787] ' />
                                        <h1 className='delivery_text'>Delivered <span className='span_delivery_text'>Shipement 1 / 1 - 1 ite, (Delivered)</span></h1>
                                    </div>
                                    <h2 className='span_delivery_text'>Delivered on Monday,20 Sepetember</h2>
                                </div>
                                <button className='return_btn'>RETURN / EXCHANGE</button>
                            </div>

                            <div>
                                <p className='span_delivery_text'>Shipping to</p>
                                <h1 className='delivery_text'>John Doe</h1>
                            </div>

                        </div>

                        <div className='product_order_details'>
                            <img src={pro2} style={{ width: 120, height: 120 }} />
                            <div>
                                <h1 className='product_name'>Bio Oil</h1>
                                <p className='price_text'>&#8361;250.0<span className='span_delivery_text'>(Includes Convenience Fee)</span></p>
                                <p className='span_delivery_text'>Size :<span className='price_text'>250ml</span></p>
                                <p className='span_delivery_text'>Return / Exchange till 30 Sep 2023  </p>
                            </div>
                        </div>

                    </div>

                    {/* Product price deetails */}

                    <div>
                        <div className='od_price_container_wrap'>
                            <p>Order#<span className='span_delivery_text'>(1 item)</span></p>
                            <p className='span_delivery_text'>Order placed on 11th September 2023</p>
                            <p className='span_delivery_text'>Paid by Cash in Delivery</p>

                        </div>

                        <div className='od_price_container_wrap_child'>
                            <h1 className='od_deetail_heading '>Order Payment Details</h1>
                            <div>
                                <tr className='flex justify-between'>
                                    <td className='od_data_text'>Order Amount</td>
                                    <td className='price_text'>₩3566.50</td>
                                </tr>
                                <tr className='flex justify-between'>
                                    <td className='od_data_text'>Order Saving</td>
                                    <td className='od_price_text'>₩850</td>
                                </tr>
                                <tr className='flex justify-between'>
                                    <td className='od_data_text'>Coupon Savings</td>
                                    <td className='od_price_text'>₩100</td>
                                </tr>
                                <tr className='flex justify-between'>
                                    <td className='od_data_text'>Convenience Fee</td>
                                    <td className='od_price_text'>₩36.50</td>
                                </tr>
                                <tr className='flex justify-between border-b  border-[#DEDEDE] pb-1'>
                                    <td className=''>Order Total</td>
                                    <td className='price_text'>₩3566.50</td>
                                </tr>
                                <p className='border-b  border-[#DEDEDE] pb-1'>Payment Mode</p>
                                <tr className='flex justify-between'>
                                    <td>Cash On Delivery</td>
                                    <td className='price_text'>₩3566.50</td>
                                </tr>
                            </div>
                        </div>

                        <div className='od_price_container_wrap_child2'>
                            <h1 className='od_deetail_heading'>Deliver to</h1>
                            <p className='delivery_text'>John Doe<span className='od_span_delivery_text'>HOME</span></p>
                            <p className='od_add_text '>53, Noryangjin-ro 14, Seolleung-ro 125-gil Gangnam-gu  Daebang-dong, Seoul</p>
                            <p className='phone_txt'>Phone :<span className=''></span> 9876543210 </p>
                        </div>

                    </div>
                </div>

            </section>

        </>
    )
}

export default OrderDeetails