import React from 'react'
import wishlistImage from '../../assests/discriptionImage/wishlist.png';
import '../../css/My_account/wishlist_nothing.css';

const Wishlist_nothing = () => {
    return (
        <>
            <section className='nothing_list_wrap'>
                <div className='nothing_list_wrap_child'>
                    <img src={wishlistImage} />
                    <p className=''>You have not added any products to your wishlist</p>
                    <button className='wishlist_btn'>Wishlist Now</button>
                </div>
            </section>




        </>


    )
}

export default Wishlist_nothing