import { useState } from 'react';
import '../../css/My_account/order_return.css';
import pro1 from '../../assests/filterProductImage/pw2.webp';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';


const OrderAndReturn = () => {
    const months = [
        'Last3month', 'Last6Month', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December',
    ];

    const [selectedMonth, setSelectedMonth] = useState('');

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const monthContent = {
        Last3month: (
            <div className='last_month'>
                <h1>You have not made any purchase yet</h1>
                <button className='continue_btn'>Continue Shopping</button>
            </div>
        ),

        Last6Month: (
            <section className='space-y-4 md:mb-0 mb-3'>
                <div>
                    {/* order id */}
                    <h1 className='order_id'>Order ID : 256421ER5264TM</h1>
                    <Link to='/order_deetails'>
                        <div className='od_rt_deetails'>
                            <img src={pro1} className='od_rt_product' />
                            <div>
                                <h1 className='od_rt_delivery_text'>Delivered</h1>
                                <p className='delivery_date'>Delivered on 20 Sep 2023</p>
                            </div>
                            <MdOutlineKeyboardArrowRight size={40} className='md:ml-[550px]' />
                        </div>
                    </Link>
                </div>
                <div>
                    {/* order id */}
                    <h1 className='order_id'>Order ID : 256421ER5264TM</h1>
                    <div className='od_rt_deetails'>
                        <img src={pro1} className='od_rt_product' />
                        <div>
                            <h1 className='od_rt_delivery_text'>Delivered</h1>
                            <p className='delivery_date'>Delivered on 20 Sep 2023</p>
                        </div>
                        <MdOutlineKeyboardArrowRight size={40} className='md:ml-[550px]' />
                    </div>
                </div>
                <div>
                    {/* order id */}
                    <h1 className='order_id'>Order ID : 256421ER5264TM</h1>
                    <div className='od_rt_deetails'>
                        <img src={pro1} className='od_rt_product' />
                        <div>
                            <h1 className='od_rt_delivery_text'>Delivered</h1>
                            <p className='delivery_date'>Delivered on 20 Sep 2023</p>
                        </div>
                        <MdOutlineKeyboardArrowRight size={40} className='md:ml-[550px]' />
                    </div>
                </div>
                <div>
                    {/* order id */}
                    <h1 className='order_id'>Order ID : 256421ER5264TM</h1>
                    <div className='od_rt_deetails'>
                        <img src={pro1} className='od_rt_product' />
                        <div>
                            <h1 className='od_rt_delivery_text'>Delivered</h1>
                            <p className='delivery_date'>Delivered on 20 Sep 2023</p>
                        </div>
                        <MdOutlineKeyboardArrowRight size={40} className='md:ml-[550px]' />
                    </div>
                </div>
            </section>
        ),
        March: (
            <div>
                <h2>Content for February</h2>
                <p>This is another box with different content:</p>
                <div className="content-box">

                    <p>march</p>
                </div>
            </div>
        ),
        April: (
            <div>
                <h2>Content for February</h2>
                <p>This is another box with different content:</p>
                <div className="content-box">

                    <p>april</p>
                </div>
            </div>
        ),
        May: (
            <div>
                <h2>Content for February</h2>
                <p>This is another box with different content:</p>
                <div className="content-box">

                    <p>may</p>
                </div>
            </div>
        ),
        June: (
            <div>
                <h2>Content for February</h2>
                <p>This is another box with different content:</p>
                <div className="content-box">

                    <p>June</p>
                </div>
            </div>
        ),
        July: (
            <div>
                <h2>Content for February</h2>
                <p>This is another box with different content:</p>
                <div className="content-box">

                    <p>July</p>
                </div>
            </div>
        ),

    };

    return (
        <>
            <div className='md:hidden block'>
                <Navbar />
            </div>

            <section>
                <div className="or_re_main_wrap">
                    <select id="month" className="or_re_select_box" value={selectedMonth} onChange={handleMonthChange}>
                        <option value="" disabled>Select...</option>
                        {months.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>

                    <div className="od_rt_box">
                        {selectedMonth && (
                            <div>
                                {monthContent[selectedMonth] || 'Select a month to see content'}
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <div className='md:hidden block'>
                <Footer />
            </div>
        </>
    );
}

export default OrderAndReturn;
