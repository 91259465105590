import Q2Q from '../assests/homeImage/navbarLogo/middleLogo.png';
import { BsTelephoneFill } from 'react-icons/bs'
import { CiLocationOn } from 'react-icons/ci';
import { AiTwotoneMail } from 'react-icons/ai';
import { CiFacebook } from 'react-icons/ci';
import { BsInstagram } from 'react-icons/bs';
import { RiTwitterXFill } from 'react-icons/ri';

import '../css/footer.css';

function Footer() {
    return (
        <>
            <div className='footer'>

                <div className='footerInsideContainer '>
                    <div className='footerFristConatiner'>

                        <div className=" footerInsideFristConatiner ">

                            <div className='contactAndLogo'>
                                <img src={Q2Q} className='logo' />
                                <h1 className='contactus'>CONTACT US</h1>
                            </div >

                            <div className='Icons'>

                                <div className='IconsBox'>
                                    <BsTelephoneFill className='telefone' />
                                    <p className='customText'>Phone No</p>
                                </div>

                                <div className='IconsBox'>
                                    <CiLocationOn className='locationIcon' />
                                    <p className='customText'>Bhutani Garden Noida</p>
                                </div>

                                <div className='IconBoxThird'>
                                    <AiTwotoneMail className='locationIcon' />
                                    <p className='customText'>mailaddress@info.com</p>
                                </div>


                            </div>
                        </div>

                        <div className=" footerInsiderSecendContainer ">

                            <h1 className='QuickLinks'>QUICK LINKS</h1>

                            <div className='mycustomTextParents'>
                                <p className='customText'>About</p>
                                <p className='customText'>Other Policies</p>
                                <p className='customText'>Refund</p>
                                <p className='customText'>Consecturer</p>
                            </div>
                        </div>


                    </div>

                    <div className='footerSecendContainer'>

                        <div className="footerSecendInsideFristContainner">

                            <h1 className='letsHelpYou'>LET US HELP YOU</h1>

                            <div className='mycustomTextParents'>
                                <p className='customText'>About</p>
                                <p className='customText'>Other Policies</p>
                                <p className='customText'>Refund</p>
                                <p className='customText'>Consecturer</p>
                            </div>

                        </div>

                        <div className="footerSecendInsideFristContainner  ">

                            <h1 className='followUs'>follow us</h1>

                            <div className='flex flex-col items-start mt-[44px] gap-[18px] text-white'>
                                <div className='IconsBox'>
                                    <CiFacebook className='footerSecendInsideicons' />
                                    <p className='customText'>Facebook</p>
                                </div>

                                <div className='IconsBox'>
                                    <BsInstagram className='footerSecendInsideicons' />
                                    <p className='customText'>Instagram</p>
                                </div>

                                <div className='IconsBox'>
                                    <RiTwitterXFill className='footerSecendInsideicons' />
                                    <p className='customText '>Twitter</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='footerbottom'>
                    <div className='footerbottominside'>
                        <p className='allRightes'>All rights reserved 2023 </p>
                        <div className='privacyContainer'>
                            <p className='privacyText'>Privacy Policy</p>
                            <p className='privacyText'>Terms & Conditions</p>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default Footer;