import React from 'react'
import '../../css/My_account/saved_card.css';
import atmcard from '../../assests/myAccountImage/atm-card 1.png';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Saved_card = () => {
    return (
        <>
            <div className='md:hidden block'>
                <Navbar />
            </div>
            <div className='saved_card_container'>
                <img src={atmcard} />
                <p className='font-roboto text-[#878787] text-[20px]'>No Saved Cards</p>
            </div>

            <div className='md:hidden block mt-3'>
                <Footer />
            </div>

        </>
    )
}

export default Saved_card;