import React from 'react'
import { useState, useEffect } from 'react'
import *as  CONSTANT from '../constant'
import axios from 'axios'
import Navbar from './Navbar';
import Footer from './Footer';
import '../css/cart.css';
import azito from '../assests/filterProductImage/pw4.webp';
import hurray from '../assests/discriptionImage/hurray.png';
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { RxMinus } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { VscClose } from "react-icons/vsc";
import { Link } from 'react-router-dom';



const Cart = () => {
    var loggedInId = sessionStorage.getItem("user_id");

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [cartId, setCartId] = useState();
    const [isNestedpopupOpen, setIsNestedpopupOpen] = useState(false)

    const openPopup = (e) => {
        setCartId(e);
        setPopupOpen(true);
    }

    const closePopup = () => {
        setPopupOpen(false);
    }

    // nested popup

    const openNestedpopup = () => {
        setIsNestedpopupOpen(true);
    }

    const closeNestedPopup = () => {
        setIsNestedpopupOpen(false)
    }

    // for quantity dropdown
    const [quantity, setQuantity] = useState(1);
    const handleQuantityChange = (e) => {
        setQuantity(parseInt(e.target.value, 10));
    };
    const navigate = useNavigate();
    //   for liked Heart icon
    const deleteItem = async e => {
        let data = {
            cart_id: e,
        }

        const res = await axios.post(`${CONSTANT.baseUrl}/api/deleteQuantity/`, data);
        // set the state of the user

        if (res.data.code == '200') {
            window.location.reload();
        }
        else {
            alert(res.data.MESSAGE);
        }
    };
    const [isOpen, setIsOpen] = useState(false);


    const increaseQuantity = async e => {
        let data = {
            type: "increase",
            cart_id: e,
        }

        const res = await axios.post(`${CONSTANT.baseUrl}/api/updateQuantity/`, data);
        // set the state of the user

        if (res.data.code == '200') {
            window.location.reload();
        }
        else {
            alert(res.data.MESSAGE);
        }

    };
    const decreaseQuantity = async e => {
        let data = {
            type: "decrease",
            cart_id: e,
        }

        const res = await axios.post(`${CONSTANT.baseUrl}/api/updateQuantity/`, data);
        // set the state of the user

        if (res.data.code == '200') {
            window.location.reload();
        }
        else {
            alert(res.data.MESSAGE);
        }

    };
    const [likedCards, setLikedCards] = useState([false]);
    const [cartproducts, setcartProducts] = useState([{
        name: "",
        price: "",
        image: "",
        quantity: "",
    }]);
    const handleLikeClick = (cardNumber) => {
        setLikedCards((prevLikedCards) => {
            const updatedLikedCards = [...prevLikedCards];
            updatedLikedCards[cardNumber] = !updatedLikedCards[cardNumber];
            return updatedLikedCards;
        });
    };
    useEffect(() => {
        fetchcartProducts();
    }, []);
    const fetchcartProducts = async () => {

        var total = 0;
        let array = []
        const data = {

        }

        const res = await axios.get(`${CONSTANT.baseUrl}/api/get_cart_items/${loggedInId}`, data);
        for (let item of res.data.DATA) {
            if (item.proName) {
                total = total + (item.quantity * item.price);

                array.push(item)
            }

        }
        setcartProducts(array);
        sessionStorage.setItem('cartTotal', total);
        console.log(cartproducts);





    };
    return (
        <>
            <Navbar />
            <h1 className='_cart_heading'>My Cart</h1>

            {cartproducts && sessionStorage.getItem('cartTotal') != 0 && sessionStorage.getItem("user_id") ? (
                <section className='_main_wrap'>
                    <div className='cart_wrap'>


                        <div className='_cart_main_wrap'>
                            <div className="grid grid-flow-rows auto-rows-max">
                                {/* frist product box */}
                                {
                                    cartproducts.map((item, i) =>

                                        <div className='_cart_wrap_child'>
                                            <img src={item.image} className='_cart_image' />

                                            {/* product summery  */}
                                            <div className='_Cart_child_text'>
                                                <h1 className='_child_text_dis'>{item.proName}</h1>
                                                <h1 className='_child_text_dis'> $ {item.price}</h1>
                                                <div className='_child_Pro_name'>

                                                </div>
                                                <div>


                                                </div>

                                                <div className='_quantity_wrap'>
                                                    <div>
                                                        <label className='_child_text_dis' htmlFor="quantity">Quantity </label>
                                                        <div className="_quantity_btn_box">
                                                            <button onClick={() => decreaseQuantity(item._id)} className=''> <RxMinus /> </button>
                                                            <span className="px-3 text-[14px]">{item.quantity}</span>
                                                            <button onClick={() => increaseQuantity(item._id)} className=''> <GoPlus /> </button>
                                                        </div>
                                                    </div>

                                                    <div>

                                                    </div>

                                                    <MdDeleteOutline size={20} className='_remove_item' onClick={() => openPopup(item._id)} />

                                                </div>

                                            </div>

                                        </div>

                                    )}
                            </div>


                            {/* secend product box */}

                            <div className='cart_wrap_child_two'>



                                <div className='sub_price_main'>

                                    <div className='table_body'>
                                        <tr className='table_row'>
                                            <td className='_table_row_text'>Bag Total</td>
                                            <td className='table_data_text'>$ {sessionStorage.getItem('cartTotal')}</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='_table_row_text'>Delivery Fee</td>
                                            <td className='table_data_text'>$ 0</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='_table_row_text'>Bag Subtotal</td>
                                            <td className='table_data_text'>$ {sessionStorage.getItem('cartTotal')}</td>
                                        </tr>

                                    </div>

                                    <div className='_sub_total_box'>
                                        <div>
                                            <h1 className='total_price'>Total</h1>
                                            <h1 className='total_price_number'>$ {sessionStorage.getItem('cartTotal')}</h1>

                                        </div>
                                        <Link to={'/checkout'}>
                                            <button className='_checkout_btn'>
                                                Checkout
                                            </button>
                                        </Link>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                    {isPopupOpen && (
                        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
                            <div className="bg-white p-8 rounded shadow-lg md:w-[300px] md:m-0 m-4 ">
                                <div className='flex-col items-center justify-center' >

                                    <p className="mb-2  font-roboto text-center">Are you sure you want to delete? </p>

                                </div>
                                <div className='flex justify-between mt-7'>
                                    <button className="_save_btn-cart " onClick={closePopup}>
                                        Close
                                    </button>
                                    <button className="_save_btn-cart" onClick={() => deleteItem(cartId)}>
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* nested popup  */}

                    <div className="_pop_up_main">
                        {/* Popup container and overlay */}
                        <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white p-5 border border-gray-300 shadow-lg rounded-lg  z-50 w-[400px] max-h-[60vh]  ${isNestedpopupOpen ? '' : 'hidden'}`}   >
                            <div>
                                <div className='main_hurray_box'>
                                    <img src={hurray} className='hurray_image' />
                                    <h1 className='hurray_text'>Hurray! You’ve saved ₩100</h1>
                                    <h1 className='coup_no'>QUETTAQNEW</h1>
                                    <h1 className='_applied_text'>coupon code applied for this order</h1>
                                    <button onClick={closeNestedPopup} className='_hurray_close_btn'>Hurray! Thanks!</button>
                                </div>
                            </div>
                            <button className="absolute top-2 right-2 font-roboto font-bold" onClick={closeNestedPopup} >  <VscClose size={30} /> </button>
                        </div>
                        <div className={`fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40 ${isNestedpopupOpen ? '' : 'hidden'}`} onClick={closeNestedPopup} ></div>
                    </div>


                </section>

            ) : (
                <section className='_main_wrap'>
                    <div class="cart_wrap flex flex-col gap-4 justify-center items-center">
                        <p class="_cart_text">Oops the cart is empty! </p>
                        <Link to={'/'}>
                            <button className='_checkout_btn'>
                                Continue Shopping
                            </button>
                        </Link>
                    </div>
                </section>
            )}


            <Footer />


        </>
    )
}

export default Cart