import React from 'react'
import '../css/payment.css';
import { useState,useEffect } from 'react';

import Navbar from './Navbar';
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import { MdCheckCircleOutline } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { PiCreditCardThin } from "react-icons/pi";
import { BsCashStack } from "react-icons/bs";
import { TbDeviceDesktopDollar } from "react-icons/tb";
import { BsCalendar2Month } from "react-icons/bs";
import *as  CONSTANT from '../constant'
import axios from 'axios'





const Payment = () => {


    // change address back page
    const navigate = useNavigate();
    const handleChangeClick = () => {
        // Go back to the previous page for changing address
        navigate(-1);
    };


    // for acordian
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };



    const [isOpen, setIsOpen] = useState(false);
    const [orderDetail, setOrderDetail] = useState([{
        first_name: "",
        last_name: "",
        landmark: "",
        city: "",
        flat: "",
        state: "",
        pincode: "",
        phone: "",
        amount: ""
    }]);
    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
        sessionStorage.removeItem("order_id");
        setTimeout(function () { navigate("/"); }, 1000);
    }
    const placeOrder=async ()=>{
        await axios.post(`${CONSTANT.baseUrl}/api/placeOrder/${sessionStorage.getItem('order_id')}`).then((detail) => {
              console.log("response", detail.data)
              openPopup();

          }).catch(err => {
              console.warn(err)
          })
    
         };
    const FETCH_ORDER_DETAIL_ON_LOAD=async ()=>{
        await axios.get(`${CONSTANT.baseUrl}/api/fetchOrderDetail/${sessionStorage.getItem('order_id')}`).then((detail) => {
              console.log("response", detail.data)
              setOrderDetail(detail.data.ORDER_INFO[0]);


          }).catch(err => {
              console.warn(err)
          })
    
         };

    useEffect(() => {
    if(!sessionStorage.getItem('user_id') || !sessionStorage.getItem('order_id'))  {
    navigate('/');
    }
    FETCH_ORDER_DETAIL_ON_LOAD();
  }, []);

    return (
        <>

            <Navbar />

            <section className='main_wrap'>
                <div className='main_child_wrap'>
                    <div className='child_wrap'>
                        <h1 className='checkout_heading'>Checkout</h1>

                        <div className='boxes_main_wrap'>


                            <div className='pay_main_wrap'>
                                <div className='child_box_frist'>
                                    {/* delivery address====================================== */}
                                    <div className='deli_main_wrap'>
                                        <div className='delivery_add_wrap'>
                                            <MdCheckCircleOutline size={20} className='text-[#2DA502]' />
                                            <h1 className='delivery_text'>Delivery Address</h1>
                                        </div>
                                        <button onClick={handleChangeClick} className='cng_btn'>Change</button>
                                    </div>
                                    <h1 className='final_add'>Home<span className='final_add_span'>{orderDetail.flat},{orderDetail.city},{orderDetail.state},{orderDetail.pincode}</span></h1>
                                </div>

                                <div className='pay_box'>
                                    <h1 className='pay_heading'>Payment Method</h1>

                                    {/* Credit card */}
                                    <div >
                                        <div className="Pay_accordian_box" onClick={() => toggleAccordion(0)}>
                                            <div className='card_icon'>
                                                <PiCreditCardThin size={18} className='text-[#636363] ' />
                                                <h1 className='_accord_text_'>Debit Card</h1>
                                            </div>
                                            <div className="text-sm">{activeIndex === 0 ? <MdOutlineKeyboardArrowRight size={20} /> : <MdOutlineKeyboardArrowDown size={20} />}</div>
                                        </div>
                                        {activeIndex === 0 && (
                                            <div>
                                                <form id="creditCardForm" className='credit_card_form'>
                                                    <input type="text" id="cardNumber" className="cardNumber" placeholder="Enter your card number" required />

                                                    <input type="text" id="expirationDate" className="cardNumber" placeholder="MM/YYYY" required />

                                                    <input type="text" id="cvv" className="cardNumber" placeholder="Enter CVV" required />

                                                </form>
                                            </div>
                                        )}
                                        <div className='line_hori'></div>
                                    </div>

                                    {/* Debit card */}

                                    

                                    {/* EMI */}
                                    

                                    {/* Net Baning */}

                                    

                                    {/* Cash On Delivery */}

                                    


                                </div>

                            </div>


                            {/* total payable box =============================================*/}
                            <div className='total_Payable_main'>

                                <div className='table_body'>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Bag Total</td>
                                        <td className='table_data_text'>$ {orderDetail.amount}</td>
                                    </tr>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Delivery Fee</td>
                                        <td className='table_data_text'>$ 0</td>
                                    </tr>
                                    <tr className='table_row'>
                                        <td className='_table_row_text'>Bag Subtotal</td>
                                        <td className='table_data_text'>$ {orderDetail.amount}</td>
                                    </tr>
                                    


                                </div>

                                <div className='_sub_total_box'>
                                    <h1 className='total_price'>Total Payable</h1>
                                    <h1 className='total_price_number'>$ {orderDetail.amount}</h1>
                                </div>

                                <div className='flex items-center justify-center pb-4'>
                                    <button onClick={() => placeOrder()} className=' _save_btn'>Place order</button>
                                </div>

                            </div>


                            {isOpen && (
                                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
                                    <div className="bg-white p-8 rounded shadow-lg md:w-[600px] w-full ">
                                        <div className='flex-col items-center justify-center border-b border-gray-600' >
                                            <h2 className="text-[40px] font-roboto font-bold mb-1 text-center">Thank you !</h2>
                                            <p className="mb-2  font-roboto text-center">Your order has been placed and a translator will start work shortly </p>
                                            <p className="mb-2 font-roboto text-center">Your order Number is: {sessionStorage.getItem('order_id')}</p>
                                        </div>


                                        <div className='mt-2'>
                                           

                                            

                                            <div className='flex items-center justify-between mt-5 pt-3 border-t border-gray-600 border-b pb-3'>
                                                <h1 className='total_price'>Total paid amount</h1>
                                                <h1 className='total_price_number'>$ {orderDetail.amount}</h1>
                                            </div>

                                            <p className="mb-2 font-roboto text-center mt-3">Estimated delivery time <span className='font-roboto font-bold'> 3 days</span></p>



                                        </div>

                                        <button className="_save_btn mt-4" onClick={closePopup}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}



                        </div>


                    </div>
                </div>

            </section>

            <Footer />


        </>

    )
}

export default Payment