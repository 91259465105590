import React from 'react'
import { useState } from 'react';
import ProfileOverview from './ProfileOverview';
import '../../css/my_account.css';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { FaCubesStacked } from "react-icons/fa6";
import { FaBoxOpen } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { CiCreditCard2 } from "react-icons/ci";
import { CiBellOn } from "react-icons/ci";
import OrderAndReturn from './OrderAndReturn';
import Wishlist_nothing from './Wishlist_nothing';
import Wishlist from './Wishlist';
import New_Address from './New_Address';
import Saved_add from './Saved_add';
import Saved_card from './Saved_card';
import Notification from './Notification';


const Myaccount = () => {
    const [activeTab, setActiveTab] = useState('Tab 1');
    const handleTabClick = (tabLabel) => {
        setActiveTab(tabLabel);
    };

    const renderTabContent = () => {
        if (activeTab === 'Tab 1') {
            return <div><ProfileOverview /></div>;
        } else if (activeTab === 'Tab 2') {
            return <div><OrderAndReturn /></div>;
        } else if (activeTab === 'Tab 3') {
            return <div><Wishlist /></div>;
            // return <div><Wishlist_nothing /></div>;
        }
        else if (activeTab === 'Tab 4') {
            // return <div><New_Address /></div>
            return <div><Saved_add /></div>
        }
        else if (activeTab === 'Tab 5') {
            return <div><Saved_card /></div>
        }
        else if (activeTab === 'Tab 6') {
            return <div><Notification /></div>
        }

        return null;
    };


    return (
        <>

            <Navbar />

            <div className='md:block hidden'>
                <div className='my_account_main '>
                    <div className="my_account_tab_container">
                        <h1 className='my_account_heading'>My Account</h1>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 1')} >
                            <FaCubesStacked />
                            Overview
                        </div>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 2')} >
                            <FaBoxOpen />
                            Orders & Returns
                        </div>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 3')} >
                            <CiHeart />
                            Wishlist
                        </div>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 4')} >
                            <IoLocationOutline />
                            Addresses
                        </div>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 5')} >
                            <CiCreditCard2 />
                            Saved Cards
                        </div>

                        <div className='my_account_tabBox' onClick={() => handleTabClick('Tab 6')} >
                            <CiBellOn />
                            Notifications
                        </div>

                    </div>
                    <div className="my_account_tabBox">{renderTabContent()}</div>
                </div>
            </div>
            <div className='mt-[80px]'>
                <Footer />
            </div>
        </>
    )
}

export default Myaccount;