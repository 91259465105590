import Navbar from './Navbar';
import Footer from './Footer';
import { useState } from 'react';
import *as  CONSTANT from '../constant'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import '../css/login_and_signup.css';
import {  useParams } from "react-router-dom";

function Signup() {
    const navigate = useNavigate();
    const [addreply, setAddReply] = useState({
    email: "",
    password: "", 
    cpassword :""
  });
  const { email, password,cpassword } = addreply;
  const onInputChange = e => {
    setAddReply({ ...addreply, [e.target.name]: e.target.value });
  };
    const [user, setUser] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = async e => {
   
        e.preventDefault();
        var name_;
    if (email && password && cpassword) {
    if(password != cpassword)
    {
    setErrorMessage("Password and Confirm Password does not match!");
    }
    else
    {
      let data = {
        email: addreply.email,
        password: addreply.password,
      }
      
      const res = await axios.post(`${CONSTANT.baseUrl}/api/sign-up`, data);
      // set the state of the user

      if(res.data.code=='200')
      {

      

      name_='user';
      
      
      setUser(name_)
      // store the user in localStorage
      sessionStorage.setItem('user', name_)
      sessionStorage.setItem('user_id', res.data.DATA.ID)
      console.log(res.data)
      setTimeout(function () { navigate("/cart"); }, 1000);
      }
      else
      {
      setErrorMessage(res.data.message);
      }
      
      
      }
    } else {
    setErrorMessage("Please enter mandatory data!");
    }
        

    }
;

    

    return (

        <>

            <Navbar />
            <div className='loginContainer'>
                <div className='loginContainerInside'>
                    <div className='loginpageBox'>
                        <div className='loginTextBox'>
                            <h1 className='loginText'>Sign Up</h1>
                            <p className='welcomeText'>Welcome to QuettaQ </p>
                        </div>
                        {errorMessage && (
                       <p className="error"> {errorMessage} </p>
                     )}
                        <form className='loginForm' onSubmit={handleSubmit}>
                            <div className='emailTextBox'>
                                <label htmlFor='email' className='emailText'>Email</label>
                                <input onChange={e => onInputChange(e)} type='email'
                                    name='email' value={email} autoComplete='off' placeholder='example@address.com' className='Input' />
                            </div>
                            <div className='passwordBox'>
                                <div className='passwordTextBox'>
                                    <label htmlFor='password' className='passwordText'>Password</label>
                                    <input onChange={e => onInputChange(e)} type='password'
                                        name='password' placeholder='Password' autoComplete='off' value={password} className='Input' />
                                </div>
                               
                            </div>
                            <div className='passwordBox'>
                                <div className='passwordTextBox'>
                                    <label htmlFor='password' className='passwordText'>Confirm Password</label>
                                    <input onChange={e => onInputChange(e)} type='password'
                                        name='cpassword' placeholder='Password' autoComplete='off' value={cpassword} className='Input' />
                                </div>
                                <a className='forgetPassword' href='#' >Forgot Password</a>
                            </div>
                            <div className='buttonBox'>
                                <button type='submit' className='loginButton'>Sign Up</button>
                                <div className='haveAccount'>
                                    <p className='notAccount'>Already have an account?</p>
                                    <p onClick={() => navigate('/login')} className='signUp' >Log In</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='mt-[64px]'>
                <Footer />
            </div>
        </>
    )
}
export default Signup;